import React from "react";
import { Template } from "./Template";
import src from "./assets/norton.mp3";

export const Page2 = (props) => {
  return (
    <Template
      {...props}
      name="Eleanor Holmes Norton"
      description="Conversation with Black feminists Eleanor Holmes Norton, Flo Kennedy, Vertamae Smart Grosvenor, and others about the role of the Black woman in America. Moderated by Ed Cumberbatch and produced by Kay Lindsey."
      date="1968"
      credit="Pacifica Radio Archives"
      duration="4:11"
      src={src}
      phrases={phrases}
    />
  );
}

const phrases = [
  {
    start: 180,
    end: 4260,
    text: "INTERVIEWER: There's been a lot of discussion recently"
  },
  {
    start: 4260,
    end: 9260,
    text: 'of the revolution within American society of the female.'
  },
  {
    start: 10860,
    end: 14583,
    text: "She's holding jobs she never held before. And she's been...",
    paragraph: true,
  },
  { start: 16050, end: 16883, text: '(Florynce Kennedy:) Name one.', paragraph: true },
  { start: 19140, end: 19973, text: '(Interviewer:) May I?', break: true },
  {
    start: 21310,
    end: 26310,
    text: "She's become sought after by political candidates"
  },
  {
    start: 26580,
    end: 31410,
    text: 'as a very significant block vote,'
  },
  {
    start: 31410,
    end: 35190,
    text: 'but very fundamentally connected with this'
  },
  {
    start: 35190,
    end: 38910,
    text: 'assumed revolution is the pill.'
  },
  {
    start: 38910,
    end: 42790,
    text: "And I'm wondering if there is any meaning"
  },
  { start: 43740, end: 45960, text: 'concerning this invention' },
  {
    start: 45960,
    end: 47550,
    text: 'that has to do with Black women'
  },
  {
    start: 47550,
    end: 51813,
    text: "that just hasn't been talked about?"
  },
  {
    start: 54390,
    end: 58670,
    text: 'Does it apply the same, in other words, as a convenience'
  },
  {
    start: 59880,
    end: 63720,
    text: 'for Black women as opposed to white women, Eleanor?',
    paragraph: true,
  },
  {
    start: 63720,
    end: 66240,
    text: '(Norton:) Well, yeah, I think the pill, as seen in society at large,'
  },
  {
    start: 66240,
    end: 68670,
    text: 'is a liberating factor for women.'
  },
  { start: 68670, end: 70350, text: 'It has liberated women' },
  {
    start: 70350,
    end: 73710,
    text: 'from the age-old biological dependency'
  },
  {
    start: 73710,
    end: 75870,
    text: 'that comes from getting pregnant'
  },
  {
    start: 75870,
    end: 77400,
    text: "when you don't want to get pregnant."
  },
  {
    start: 77400,
    end: 81630,
    text: "I think it's unfortunate that the pill has not as yet,"
  },
  { start: 81630, end: 82680, text: 'in my opinion at least,' },
  {
    start: 82680,
    end: 85420,
    text: 'had the effect in the Black community'
  },
  {
    start: 86520,
    end: 87810,
    text: "that it's had in the white community,"
  },
  {
    start: 87810,
    end: 89460,
    text: 'and that it ultimately will have.'
  },
  {
    start: 90810,
    end: 93390,
    text: "One thing I really don't understand is the notion"
  },
  { start: 93390, end: 96311, text: 'that Black women somehow,' },
  {
    start: 96311,
    end: 98460,
    text: "that it's revolutionary for Black women"
  },
  {
    start: 98460,
    end: 101242,
    text: 'to continue to have as many children'
  },
  {
    start: 101242,
    end: 104293,
    text: 'as their health will allow them to bear'
  },
  { start: 104293, end: 107280, text: 'while other women' },
  {
    start: 107280,
    end: 109650,
    text: 'are going in exactly the opposite direction.'
  },
  { start: 109650, end: 112530, text: 'I think the pill' },
  {
    start: 112530,
    end: 116430,
    text: 'can transform life in any country for any group of people,'
  },
  { start: 116430, end: 120790, text: 'but what it can do for women' },
  {
    start: 121740,
    end: 126740,
    text: 'who belong to an oppressed group tied and bound economically'
  },
  {
    start: 128010,
    end: 131321,
    text: 'for whom childbirth with all its joys'
  },
  { start: 131321, end: 135090, text: 'has also had its sorrows' },
  {
    start: 135090,
    end: 138480,
    text: 'in greater proportion than it has had for white women'
  },
  {
    start: 138480,
    end: 141960,
    text: 'is, I think, potentially astounding.'
  },
  {
    start: 141960,
    end: 146490,
    text: 'The notion of planned births for Black women, it seems to me,'
  },
  {
    start: 146490,
    end: 149670,
    text: 'is a far more revolutionary concept'
  },
  { start: 149670, end: 150870, text: 'than it is for white women.' },
  {
    start: 150870,
    end: 154084,
    text: 'White women have been one way or the other controlling'
  },
  {
    start: 154084,
    end: 158103,
    text: 'their birthing sometimes imperfectly before the pill.'
  },
  {
    start: 163385,
    end: 165687,
    text: 'For Black women it was often a case'
  },
  {
    start: 165687,
    end: 167970,
    text: 'of getting toward middle class status'
  },
  {
    start: 167970,
    end: 170970,
    text: 'before one even incorporated that notion.'
  },
  {
    start: 170970,
    end: 175840,
    text: 'The pill, however, is increasingly being manufactured'
  },
  { start: 176820, end: 178350, text: 'cheaper and cheaper,' },
  {
    start: 178350,
    end: 182037,
    text: 'which means that you finally have the possibility'
  },
  { start: 182037, end: 184710, text: 'in this country, at least,' },
  {
    start: 184710,
    end: 189710,
    text: 'that this liberating little pharmaceutical miracle'
  },
  {
    start: 191520,
    end: 194790,
    text: 'may make it possible for Black women'
  },
  {
    start: 194790,
    end: 197100,
    text: 'to do their thing their own way.'
  },
  {
    start: 197100,
    end: 199680,
    text: "Now, I don't think that has anything to do with"
  },
  {
    start: 199680,
    end: 202050,
    text: 'whether or not one wants to bear 15 children,'
  },
  {
    start: 202050,
    end: 204000,
    text: 'or one wants to bear no children.'
  },
  {
    start: 204000,
    end: 207270,
    text: 'The important thing it seems to me is that decision-making'
  },
  {
    start: 207270,
    end: 212270,
    text: 'be introduced for childbearing in the Black community.'
  },
  {
    start: 213690,
    end: 216990,
    text: 'Not that Black children be no longer produced,'
  },
  {
    start: 216990,
    end: 219450,
    text: 'but that somehow a Black woman'
  },
  {
    start: 219450,
    end: 223560,
    text: 'not be a biological slave to her uterus.'
  },
  {
    start: 223560,
    end: 225453,
    text: "I don't wanna eliminate her uterus,"
  },
  {
    start: 228270,
    end: 230580,
    text: 'but I do want her to be able to make the decision'
  },
  {
    start: 230580,
    end: 235580,
    text: 'along with whatever mate she is dealing with'
  },
  {
    start: 235620,
    end: 240180,
    text: 'about that terribly crucial factor'
  },
  {
    start: 240180,
    end: 242370,
    text: "of when she's gonna have children,"
  },
  {
    start: 242370,
    end: 243810,
    text: "and how many children she's gonna have."
  },
  {
    start: 243810,
    end: 246600,
    text: "And once she's able to make that decision, it seems to me,"
  },
  {
    start: 246600,
    end: 249390,
    text: 'a thousand other kinds of decisions'
  },
  {
    start: 249390,
    end: 250893,
    text: 'are going to be influenced by it.'
  }
];
