import React from "react";
import { Template } from "./Template";
import src from "./assets/dobbins.mp3";

export const Page6 = (props) => {
  return (
    <Template
      {...props}
      name="Del Dobbins"
      description="Del Dobbins of Sophie&rsquo;s Parlor Media Collective discusses the sexual assault cases of Inez Garcia, Joan Little and others, and gives context to women of color rape crisis organizing, Washington, DC."
      date="1977"
      credit="Pacifica Radio Archives"
      duration="2:00"
      src={src}
      phrases={phrases}
    />
  );
}

const phrases = [
  { start: 120, end: 1890, text: "More and more cases like Joan's" },
  { start: 1890, end: 3630, text: "grab the nation's attention." },
  {
    start: 3630,
    end: 7380,
    text: 'Joan, a Black woman, drew Black, white, Brown,'
  },
  {
    start: 7380,
    end: 10170,
    text: 'feminists, civil rights activists, and many others'
  },
  {
    start: 10170,
    end: 12750,
    text: 'to her case, and to the issue of sexual assault,'
  },
  { start: 12750, end: 14493, text: 'like no case before hers.', paragraph: true },
  {
    start: 15360,
    end: 19200,
    text: 'Accused of murdering her white male jailkeeper,'
  },
  {
    start: 19200,
    end: 21870,
    text: 'Joan, incarcerated for petty larceny,'
  },
  {
    start: 21870,
    end: 23430,
    text: 'fought the murder charge with a plea'
  },
  {
    start: 23430,
    end: 25770,
    text: "based on a woman's right to defend herself"
  },
  {
    start: 25770,
    end: 28053,
    text: 'against sexual advances and assault.'
  },
  {
    start: 28890,
    end: 31290,
    text: 'In Raleigh, North Carolina, the South,'
  },
  { start: 31290, end: 33360, text: 'white judge, Black and white,' },
  {
    start: 33360,
    end: 36090,
    text: 'male and female defense lawyers,'
  },
  { start: 36090, end: 37170, text: 'and after much struggle,' },
  {
    start: 37170,
    end: 40470,
    text: 'a Black and white male and female jury,'
  },
  {
    start: 40470,
    end: 43680,
    text: 'with the components, the major components in her case,'
  },
  {
    start: 43680,
    end: 47043,
    text: 'racism and sexism dominated the courtroom drama.',
    pargraph: true
  },
  {
    start: 47970,
    end: 49920,
    text: "Joan's case has since been highlighted"
  },
  {
    start: 49920,
    end: 52740,
    text: "by Inez Garcia's California struggle"
  },
  {
    start: 52740,
    end: 55470,
    text: 'on a self-defense plea in a controversial case'
  },
  {
    start: 55470,
    end: 58500,
    text: 'that split the feminist community nationally,'
  },
  {
    start: 58500,
    end: 62640,
    text: "and by Yvonne Wanrow's ongoing Washington state battle"
  },
  {
    start: 62640,
    end: 64920,
    text: 'to defend against a manslaughter charge'
  },
  {
    start: 64920,
    end: 68850,
    text: 'for her attempt to protect her children from sexual assault.',
    paragraph: true
  },
  {
    start: 68850,
    end: 73590,
    text: 'Inez, a Chicana, Yvonne, Colville Indian, and Joan,'
  },
  {
    start: 73590,
    end: 76410,
    text: 'all women of color fighting to protect themselves'
  },
  { start: 76410, end: 78180, text: 'and their children.' },
  { start: 78180, end: 80310, text: 'Protection, the key word,' },
  {
    start: 80310,
    end: 82503,
    text: 'particularly when we ask the question,'
  },
  {
    start: 83430,
    end: 86583,
    text: 'what of men of color and of sexual assault?',
    paragraph: true
  },
  {
    start: 87600,
    end: 89580,
    text: 'Historically, the fear of a rape charge'
  },
  { start: 89580, end: 91290, text: 'has been used to control Black' },
  { start: 91290, end: 93810, text: 'and to some extent, Brown men.' },
  {
    start: 93810,
    end: 95820,
    text: 'Not too long ago, the mere accusation'
  },
  {
    start: 95820,
    end: 98763,
    text: 'by a white woman was sufficient to result in lynching.'
  },
  {
    start: 99660,
    end: 101220,
    text: 'Black and Brown women, meanwhile,'
  },
  {
    start: 101220,
    end: 105330,
    text: 'were seldom believed or considered victims of rape.',
    paragraph: true
  },
  {
    start: 105330,
    end: 107280,
    text: 'Inevitably, Black women have ended up'
  },
  {
    start: 107280,
    end: 109200,
    text: 'in a position of protecting the Black male'
  },
  {
    start: 109200,
    end: 112920,
    text: 'against "false" rape charges,'
  },
  {
    start: 112920,
    end: 115140,
    text: 'and at the same time being compelled'
  },
  {
    start: 115140,
    end: 118230,
    text: 'to confront rising incidents of sexual assault'
  },
  {
    start: 118230,
    end: 120333,
    text: 'by Blacks within the Black community.'
  }
];
