import React from "react";
import { Template } from "./Template";
import src from "./assets/murray.mp3";

export const Page1 = (props) => {
  return (
    <Template
      {...props}
      name="Pauli Murray"
      description="Howard University student Wanda Gooch interviews Pauli Murray on Murray's civil rights work while at Howard University."
      date="April 25th, 1980"
      credit="Schlesinger Library, Harvard Radcliffe Institute/&copy; Estate of Pauli Murray, used herewith by permission of the Charlotte Sheedy Literary Agency."
      duration="0:28"
      src={src}
      phrases={phrases}
    />
  );
}

const phrases = [
  {
    start: 210,
    end: 2520,
    text: 'MURRAY: One of the things that age gives you'
  },
  { start: 2520, end: 3970, text: 'is more perspective.' },
  {
    start: 5550,
    end: 7500,
    text: 'And one of the things I have discovered'
  },
  { start: 7500, end: 10233, text: 'is that life moves in cycles.' },
  {
    start: 11730,
    end: 14070,
    text: "You see, the '60s were not the beginning,"
  },
  {
    start: 14070,
    end: 15900,
    text: 'although many people seem to think'
  },
  {
    start: 15900,
    end: 17220,
    text: "that the '60s were the beginning.",
    paragraph: true
  },
  {
    start: 17220,
    end: 19290,
    text: "(Interviewer): So, it's what you said about the '40s and the '50s.",
    paragraph: true
  },
  { start: 19290, end: 21513, text: 'MURRAY: Right here, you see,' },
  { start: 22500, end: 24270, text: 'and living with people' },
  { start: 24270, end: 27500, text: 'who had actually experienced slavery.' }
];
