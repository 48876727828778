import React from "react";
import { Template } from "./Template";
import src from "./assets/beal.mp3";

export const Page7 = (props) => {
  return (
    <Template
      {...props}
      name="Frances Beal"
      description="Frances Beal speaks about the history of SNCC and SCLC at the Black Women and Liberation Movements Conference, sponsored by the Institute for the Arts and Humanities at Howard University."
      date="November 8th, 1979"
      credit="Pacifica Radio Archives"
      duration="1:48"
      src={src}
      phrases={phrases}
    />
  );
}

const phrases = [
  {
    start: 120,
    end: 3270,
    text: 'One of the things, another point I think is important'
  },
  { start: 3270, end: 5580, text: 'for us to take a look at, is that' },
  {
    start: 5580,
    end: 9240,
    text: 'we live in this society and that we are not immune'
  },
  {
    start: 9240,
    end: 13650,
    text: 'from the cultural and ideological molding'
  },
  { start: 13650, end: 15540, text: 'of thought that goes on,' },
  {
    start: 15540,
    end: 18450,
    text: 'so that this means that within our community,'
  },
  {
    start: 18450,
    end: 23370,
    text: 'we have adopted and we are cultivated in a certain way,'
  },
  {
    start: 23370,
    end: 25890,
    text: 'so it means that within the civil rights movement, too,'
  },
  {
    start: 25890,
    end: 29220,
    text: 'there was a tremendous amount of sexism involved,'
  },
  {
    start: 29220,
    end: 31170,
    text: 'despite the fact that women played very,'
  },
  {
    start: 31170,
    end: 35010,
    text: 'very important roles, and, more than any other movement,'
  },
  { start: 35010, end: 36690, text: 'were in leadership positions.' },
  {
    start: 36690,
    end: 40980,
    text: 'There was also some very, really sexist types of behavior'
  },
  { start: 40980, end: 42060, text: 'that was present.' },
  {
    start: 42060,
    end: 45270,
    text: "I think Stokely's statement about position of women"
  },
  { start: 45270, end: 46260, text: 'in the movement is prone' },
  {
    start: 46260,
    end: 48960,
    text: "is very well known, when it's a response"
  },
  {
    start: 48960,
    end: 53340,
    text: 'to some women in SNCC raising some questions about,'
  },
  {
    start: 53340,
    end: 55260,
    text: 'you know, what the role of women is in the struggle,'
  },
  {
    start: 55260,
    end: 57630,
    text: 'because the people are out there getting themselves killed'
  },
  {
    start: 57630,
    end: 60450,
    text: 'and then they wanna come back and hear about that, you know,'
  },
  {
    start: 60450,
    end: 62310,
    text: 'that their role is a secondary one'
  },
  {
    start: 62310,
    end: 64320,
    text: "and that they're not as intelligent,"
  },
  {
    start: 64320,
    end: 67140,
    text: 'because the whole history of Black women in this country'
  },
  {
    start: 67140,
    end: 70200,
    text: 'is one of struggle and one of resistance,'
  },
  {
    start: 70200,
    end: 72900,
    text: "and it's not one where now you wanna come back"
  },
  {
    start: 72900,
    end: 77670,
    text: 'and, in society at large, talk about these incredible ideas'
  },
  {
    start: 77670,
    end: 81510,
    text: 'of freedom and independence and end of oppression'
  },
  {
    start: 81510,
    end: 86190,
    text: 'and then go home and, you know, meet lack of freedom,'
  },
  {
    start: 86190,
    end: 90540,
    text: 'lack of independence and oppression, so this is, you know,'
  },
  {
    start: 90540,
    end: 93330,
    text: "so it's not like ideas just drop from nowhere."
  },
  {
    start: 93330,
    end: 96810,
    text: "It's in the course of struggle that ideas, like, emerge"
  },
  {
    start: 96810,
    end: 100320,
    text: 'and people begin to see different types of contradictions,'
  },
  {
    start: 100320,
    end: 102960,
    text: 'and it is in the course of struggle'
  },
  {
    start: 102960,
    end: 105120,
    text: 'about where transformation takes place.'
  },
  {
    start: 105120,
    end: 108423,
    text: "It's only through struggle that transformation takes place."
  }
];
