import React from "react";
import { Template } from "./Template";
import src from "./assets/terrell.mp3";

export const Page8 = (props) => {
  return (
    <Template
      {...props}
      name="Mary Church Terrell"
      description="Mary Church Terrell reflects on her 1897 campaign to dedicate February 14th as Frederick Douglass Day across DC public schools. Her early efforts contributed to the creation of Black History Month."
      date="1952"
      credit="Henry P. Whitehead collection, Anacostia Community Museum, Smithsonian Institution, gift of Michael A. Watkins"
      duration="0:30"
      src={src}
      phrases={phrases}
    />
  );
}

const phrases = [
  {
    start: 150,
    end: 2400,
    text: 'After Frederick Douglass died, it occurred'
  },
  {
    start: 2400,
    end: 4890,
    text: 'to me that a day should be set aside'
  },
  {
    start: 4890,
    end: 7650,
    text: 'in our public schools in his honor. '
  },
  {
    start: 7650,
    end: 10810,
    text: 'I was a member of the Board of Education in 1897'
  },
  { start: 11940, end: 14280, text: 'and I moved at a board meeting' },
  {
    start: 14280,
    end: 18540,
    text: 'that the 14th day of February should hereafter be known'
  },
  {
    start: 18540,
    end: 22173,
    text: 'in our schools as Frederick Douglass Day.'
  },
  {
    start: 23340,
    end: 26160,
    text: 'This motion was unanimously counted'
  },
  {
    start: 26160,
    end: 30483,
    text: 'and for many years our schools observed Douglass Day.'
  }
];
