import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

const onDeviceReady = () => {
  if (window.cordova) {
    // eslint-disable-next-line no-undef
    StatusBar.hide();
  }

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

if (window.cordova) {
  document.addEventListener('deviceready', onDeviceReady, false);
} else {
  onDeviceReady();
}

