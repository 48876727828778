import React from "react";
import { Template } from "./Template";
import src from "./assets/johnson.mp3";

export const Page3 = (props) => {
  return (
    <Template
      {...props}
      name="Bernice Johnson Reagon"
      description="Bernice Johnson Reagon sings a poem by June Jordan to begin her lecture at Barnard College's Reid Lectureship Women's Issues Luncheon."
      date="November 11, 1980"
      credit="Pacifica Radio Archives"
      duration="3:16"
      src={src}
      phrases={phrases}
    />
  );
}

const phrases = [
  {
    start: 293,
    end: 4534,
    break: true,
    text: '♪ Washing the floors to send you to college ♪'
  },
  {
    start: 4534,
    end: 9534,
    break: true,
    text: '♪ Staying at home so you can feel safe ♪ '
  },
  {
    start: 11249,
    end: 15555,
    break: true,
    text: '♪ What do you think is the soul of her knowledge? ♪ '
  },
  {
    start: 15555,
    end: 20555,
    break: true,
    text: '♪ What do you think that makes her feel safe? ♪ '
  },
  {
    start: 21857,
    end: 26116,
    break: true,
    text: '♪ Biting her lips and lowering her eyes ♪ '
  },
  {
    start: 26116,
    end: 33116,
    break: true,
    text: "♪ To make sure there's food on the table ♪ "
  },
  {
    start: 34481,
    end: 38513,
    break: true,
    text: '♪ What do you think would be her surprise ♪ '
  },
  {
    start: 38513,
    end: 45513,
    break: true,
    text: "♪ If the world was as willing as she's able? ♪ "
  },
  {
    start: 46934,
    end: 50810,
    break: true,
    text: '♪ Hugging herself in an old kitchen chair ♪ '
  },
  {
    start: 50810,
    end: 57810,
    break: true,
    text: '♪ She listens to your hurt and your rage ♪ '
  },
  {
    start: 59240,
    end: 63482,
    break: true,
    text: '♪ What do you think she knows of despair? ♪ '
  },
  { start: 63482, end: 66974, text: '♪ What is the aching' },
  { start: 66974, end: 71510, text: 'of age? ♪ ', break: true },
  {
    start: 71510,
    end: 76510,
    break: true,
    text: '♪ Fathers, the children, the brothers turn to her ♪ '
  },
  {
    start: 77635,
    end: 82635,
    break: true,
    text: '♪ And everybody white turns to her ♪ '
  },
  {
    start: 83918,
    end: 87864,
    break: true,
    text: '♪ What about her turning around ♪ '
  },
  {
    start: 87864,
    end: 92864,
    break: true,
    text: '♪ Alone in the everyday light? ♪ '
  },
  {
    start: 96278,
    end: 100766,
    break: true,
    text: '♪ There oughta be a woman can break down ♪ '
  },
  { start: 100766, end: 103951, text: '♪ Sit down, break down,' },
  { start: 103951, end: 108590, text: 'sit down', break: true },
  { start: 108590, end: 110042, text: '♪ Like everybody else' },
  {
    start: 110042,
    end: 112682,
    break: true,
    text: 'call it quits on Monday, ♪ '
  },
  {
    start: 112682,
    end: 116839,
    break: true,
    text: '♪ Blues on Tuesday, sleep until Sunday ♪ '
  },
  {
    start: 116839,
    end: 122839,
    break: true,
    text: '♪ Down, sit down, break down, sit down. ♪ '
  },
  { start: 124626, end: 129247, text: '♪ A way outa no way' },
  { start: 129247, end: 133423, text: 'is flesh outa of flesh. ♪', break: true },
  {
    start: 133423,
    end: 139423,
    break: true,
    text: "♪ It's courage that cries out at night. ♪ "
  },
  { start: 141361, end: 146094, text: '♪ A way outa no way' },
  { start: 146094, end: 150187, text: 'is flesh outa flesh, ♪ ', break: true },
  { start: 150187, end: 155187, text: '♪ Bravery kept outa sight. ♪', break: true },
  { start: 157940, end: 162481, text: '♪ A way outa no way' },
  { start: 162481, end: 166604, text: 'is too much to ask. ♪', break: true },
  { start: 166604, end: 169675, text: '♪ Too much of a task' },
  { start: 169675, end: 172675, text: 'for any one woman. ♪', paragraph: true },
  {
    start: 174750,
    end: 178170,
    text: 'That song was a poem June Jordan wrote'
  },
  {
    start: 178170,
    end: 180280,
    text: 'after I talked to her about my mother'
  },
  {
    start: 181950,
    end: 184770,
    text: 'and I talked yesterday about my mother'
  },
  {
    start: 184770,
    end: 186750,
    text: "and her mother and her mother's mother"
  },
  { start: 186750, end: 188880, text: 'being a way outa no way' },
  {
    start: 188880,
    end: 192540,
    text: 'and I talked about Mamie Daniels and Lula Logan'
  },
  {
    start: 192540,
    end: 196863,
    text: 'and Flomila Jackson being a way outa no way.'
  }
];
