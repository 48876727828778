import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";

export const Template = ({ active, deactivating, name, description, date, duration, credit, close, phrases, src }) => {
  const [playing, setPlaying] = useState(false);
  const [currentPhrase, setCurrentPhrase] = useState(null);
  const timeouts = useRef([]);
  const audioRef = useRef(null);
  const captionRef = useRef(null);
  const inactivityTimer = useRef(null);

  const startInactivityTimer = useCallback((seconds) => {
    stopInactivityTimer();
    inactivityTimer.current = window.setTimeout(close, seconds * 1000);
  }, [close]);

  const stopInactivityTimer = () => {
    if (inactivityTimer.current) {
      window.clearTimeout(inactivityTimer.current);
    }
  }

  useEffect(() => {
    if (active) {
      startInactivityTimer(30)
    }
    return () => stopInactivityTimer();
  }, [active, startInactivityTimer]);

  useEffect(() => {
    if (!active) {
      return;
    }
    if (!audioRef.current) {
      audioRef.current = new Audio(src);
    }
    if (audioRef.current && captionRef.current) {
      audioRef.current.currentTime = 0;
      captionRef.current.scrollTop = 0;
    }
  }, [active, src]);

  const start = () => {
    if (audioRef.current) {
      audioRef.current.muted = false;
      audioRef.current.volume = 1.0;
      audioRef.current.play();
      startCaptions();
      stopInactivityTimer();
      setPlaying(true);
    }
  };

  const pause = () => {
    audioRef.current.pause();
    pauseCaptions();
    startInactivityTimer(45);
    setPlaying(false);
  };

  const onClose = () => {
    close();
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    stopCaptions();
    stopInactivityTimer();
    setPlaying(false);
  };

  const startCaptions = () => {
    const now = audioRef.current.currentTime * 1000;
    const audioTimeouts = [];
    setCurrentPhrase(null);
    phrases.forEach((phrase, index) => {
      const start = phrase.start - now;
      const end = phrase.end - now;
      if (end > 0) {
        if (start < 0) {
          highlightPhrase(index);
        } else {
          audioTimeouts.push(window.setTimeout(() => highlightPhrase(index), start < 0 ? 0 : start));
        }
        audioTimeouts.push(window.setTimeout(() => unhighlightPhrase(index), end));
      }
    });
    audioTimeouts.push(window.setTimeout(() => {
      setPlaying(false);
      stopCaptions();
      startInactivityTimer(5);
    }, phrases[phrases.length - 1].end - now));
    timeouts.current = audioTimeouts;
  };

  const highlightPhrase = index => setCurrentPhrase(index);
  const unhighlightPhrase = index => {
    if (currentPhrase === index) {
      setCurrentPhrase(null);
    }
  }

  const pauseCaptions = () => {
    timeouts.current.forEach((timeout) => window.clearTimeout(timeout));
    timeouts.current = [];
  }

  const stopCaptions = () => {
    pauseCaptions();
    setCurrentPhrase(null);
    captionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const toggle = () => {
    if (playing) {
      pause();
    } else {
      start();
    }
  }

  const jump = index => {
    stopCaptions();
    const start = phrases[index].start;
    audioRef.current.currentTime = start / 1000;
    if (audioRef.current.paused || !audioRef.current.startDate) {
      audioRef.current.play();
    }
    startCaptions();
    setPlaying(true);
    stopInactivityTimer();
  }

  return (
    <div className={classNames("page", active && "active", deactivating && "deactivating")}>
      <section>
        <div>
          {playing ? (
            <button className="play-button playing" type="button" onClick={toggle}>
              <i className="fa fa-pause" />
            </button>

          ) : (
            <button className="play-button paused" type="button" onClick={toggle}>
              <i className="fa fa-play" />
            </button>

          )}
        </div>
        <div>
          <h1>{name}</h1>
          <p>{description}</p>
        </div>
      </section>
      <section>
        <div>Recorded: {date}</div>
        <div>Duration: {duration}</div>
      </section>
      <section ref={captionRef}>
        {phrases.map((phrase, index) => <Phrase key={index} active={currentPhrase === index} phrase={phrase} onClick={() => jump(index)} />)}
      </section>
      {credit.length ? <p className="credit">Credit: {credit}</p> : null}
      <button className="back-button" type="button" onClick={onClose}>Back</button>
    </div>
  );
}

const Phrase = ({ active, phrase, onClick }) => {
  const spanRef = useRef(null);
  useEffect(() => {
    if (active && spanRef) {
      spanRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [active, spanRef]);
  return (
    <>
      <span ref={spanRef} className={active ? "active" : "inactive"} onClick={onClick}>
        {phrase.text}&nbsp;
      </span>
      {phrase.break ? <br /> : null}
      {phrase.paragraph ? <div /> : null}
    </>

  )
}