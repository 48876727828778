import React from "react";
import { Template } from "./Template";
import src from "./assets/toure.mp3";

export const Page4 = (props) => {
  return (
    <Template
      {...props}
      name="Nkenge Touré"
      description="Nkenge Touré, Director at the DC Rape Crisis Center, reflects on the struggle for personal and societal transformation to end sexism, racism and rape. Sweet Honey in the Rock sings &ldquo;Give Your Hands to Struggle.&rdquo;"
      date="1977"
      credit="Pacifica Radio Archives"
      duration="2:56"
      src={src}
      phrases={phrases}
    />
  );
}

const phrases = [
  {
    start: 180,
    end: 4143,
    text: '(Touré:) In order for there to be an elimination of rape,'
  },
  {
    start: 5040,
    end: 8310,
    text: 'a total transformation has to take place in this society,'
  },
  {
    start: 8310,
    end: 11520,
    text: 'because the rape scene, that whole thing is hooked up'
  },
  {
    start: 11520,
    end: 14250,
    text: 'into the cultures that people have,'
  },
  {
    start: 14250,
    end: 16650,
    text: 'the cultures that people are forced to have,'
  },
  {
    start: 16650,
    end: 19950,
    text: 'the socialization that inevitably no one seems'
  },
  { start: 19950, end: 21000, text: 'to be able to escape.', paragraph: true },
  {
    start: 21000,
    end: 23700,
    text: 'You may later get yourself out of it,'
  },
  {
    start: 23700,
    end: 27900,
    text: 'but you never seem to just be able to escape it.'
  },
  {
    start: 27900,
    end: 30270,
    text: 'That because of the roles that men have,'
  },
  {
    start: 30270,
    end: 32490,
    text: 'the roles that women have, the power thing,'
  },
  {
    start: 32490,
    end: 36270,
    text: 'everything, that a total transformation in society'
  },
  {
    start: 36270,
    end: 39960,
    text: 'has gotta take place to really eliminate rape.',
    paragraph: true
  },
  {
    start: 39960,
    end: 42427,
    text: 'And when people say that, they may say that very coolly,'
  },
  {
    start: 42427,
    end: 44610,
    text: '"Oh, a total transformation in society,"'
  },
  {
    start: 44610,
    end: 46590,
    text: 'but I do not think that they really understand'
  },
  { start: 46590, end: 47423, text: "what you're saying" },
  {
    start: 47423,
    end: 49770,
    text: 'when you say a total transformation in society.',
    paragraph: true
  },
  { start: 49770, end: 52290, text: 'You are talking about change.' },
  {
    start: 52290,
    end: 57290,
    text: 'People do not, all people, do not want to hear about change'
  },
  {
    start: 57570,
    end: 60030,
    text: 'and the kind of change that has to take place'
  },
  {
    start: 60030,
    end: 62670,
    text: 'for women to be respected, for men to look at women'
  },
  { start: 62670, end: 64470, text: 'as not being sexual objects,' },
  { start: 64470, end: 67770, text: 'for women to be able to deal' },
  {
    start: 67770,
    end: 69750,
    text: 'with themselves in a confident manner,'
  },
  {
    start: 69750,
    end: 73590,
    text: 'that they would confront any type situation'
  },
  {
    start: 73590,
    end: 77100,
    text: 'where they were being treated in a negative manner,'
  },
  {
    start: 77100,
    end: 78510,
    text: 'that they would challenge anyone'
  },
  {
    start: 78510,
    end: 80250,
    text: 'on those particular kinda points.',
    paragraph: true,
  },
  {
    start: 80250,
    end: 82650,
    text: 'The transformations, the real changes,'
  },
  {
    start: 82650,
    end: 84540,
    text: 'concrete changes are gonna have to take place'
  },
  {
    start: 84540,
    end: 86250,
    text: 'to bring about those kind of things.'
  },
  {
    start: 86250,
    end: 90023,
    text: 'That means redistribution of the wealth, you know.', paragraph: true
  },
  { start: 90023, end: 90856, text: '(Interviewer:) Bottom line.', paragraph: true },
  {
    start: 90856,
    end: 93360,
    text: '(Touré:) That means land for Black folks.'
  },
  {
    start: 93360,
    end: 97200,
    text: 'That means whatever it is the Spanish-speaking people want.'
  },
  {
    start: 97200,
    end: 99870,
    text: 'That means a whole lot of things.'
  },
  {
    start: 99870,
    end: 101790,
    text: 'You know, when you talk about the prevention,'
  },
  {
    start: 101790,
    end: 105003,
    text: 'that is the ultimate in prevention.',
    paragraph: true,
  },
  {
    start: 107070,
    end: 108660,
    text: 'You have things that you have learned'
  },
  {
    start: 108660,
    end: 110790,
    text: 'and have developed through your family, through your life'
  },
  {
    start: 110790,
    end: 112830,
    text: 'that you will carry to your grave with you.'
  },
  {
    start: 112830,
    end: 114780,
    text: 'There are other things, fortunately,'
  },
  {
    start: 114780,
    end: 116910,
    text: 'that you will be able to do something about'
  },
  {
    start: 116910,
    end: 120300,
    text: "if you won't have to carry those to your grave with you."
  },
  {
    start: 120300,
    end: 123060,
    text: 'And you will fight conflicts all of your life'
  },
  {
    start: 123060,
    end: 126030,
    text: 'between what you have been taught,'
  },
  {
    start: 126030,
    end: 127650,
    text: 'what has been ingrained in you,'
  },
  {
    start: 127650,
    end: 129903,
    paragraph: true,
    text: 'and what you have come to know is right.'
  },
  {
    start: 131021, end: 135756, break: true,
    text: '(Sweet Honey in the Rock:) ♪ You see me stumble ♪'
  },
  {
    start: 135756,
    end: 140378,
    break: true,
    text: "♪ Don't stand back and look on ♪"
  },
  {
    start: 140378, end: 145378, break: true,
    text: '♪ Reach out now brother ♪'
  },
  {
    start: 145685,
    end: 150685,
    break: true,
    text: '♪ Give your hands to struggle ♪'
  },
  {
    start: 151593,
    end: 156593,
    break: true,
    text: '♪ Give your hands to struggle ♪'
  },
  {
    start: 158795,
    end: 163795,
    break: true,
    text: '♪ Give your hands to struggle ♪'
  },
  {
    start: 165386,
    end: 170386,
    break: true,
    text: '♪ Give your hands to struggle ♪'
  },
  {
    start: 171559,
    end: 175392,
    break: true,
    text: '♪ Give your hands to struggle ♪'
  }
];
