import React, { useEffect, useState } from 'react';
import "./fonts/fontawesome.css";
import './App.scss';
import mic from "./assets/recording.png";
import music from "./assets/music.png";
import { Page1 } from "./pages/Page1";
import { Page2 } from "./pages/Page2";
import { Page3 } from "./pages/Page3";
import { Page4 } from "./pages/Page4";
import { Page5 } from "./pages/Page5";
import { Page6 } from "./pages/Page6";
import { Page7 } from "./pages/Page7";
import { Page8 } from "./pages/Page8";
import { AppReloader } from './AppReloader';

export const App = () => {
    const [page, setPage] = useState(null);
    const [pageOut, setPageOut] = useState(null);
    const [sleeping, setSleeping] = useState(false);

    const handleClick = (index) => {
        setPageOut(page);
        window.setTimeout(() => setPageOut(null), 250);
        setPage(index);
    }
    const closePage = () => {
        setPageOut(page);
        window.setTimeout(() => setPageOut(null), 250);
        setPage(null);
    }

    window.setInterval(() => window.innerHeight, 1000);

    useEffect(() => {
        const maybeSleep = () => {
            const hour = new Date().getHours();
            setSleeping(hour <= 7 || hour >= 21);
        }
        const sleepInterval = window.setInterval(maybeSleep, 10 * 60 * 1000);
        maybeSleep();
        return () => {
            window.clearInterval(sleepInterval);
        }
    }, []);

    if (sleeping) {
        // return null;
    }

    return (
        <AppReloader>
            <div id="toc">
                <h1>
                    <img alt="" src={mic} />
                    In Their Own Voices
                    <img alt="" src={music} />
                </h1>
                <div>
                    <ul>
                        <ListItem name="Frances Beal" onClick={() => handleClick(7)} />
                        <ListItem name="Del Dobbins" onClick={() => handleClick(6)} />
                        <ListItem name="Pauli Murray" onClick={() => handleClick(1)} />
                        <ListItem name="Eleanor Holmes Norton" onClick={() => handleClick(2)} />
                    </ul>
                    <ul>
                        <ListItem name="Bernice Johnson Reagon" onClick={() => handleClick(3)} />
                        <ListItem name="Mary Church Terrell" onClick={() => handleClick(8)} />
                        <ListItem name="Nkenge Touré" onClick={() => handleClick(4)} />
                        <ListItem name="Sweet Honey in the Rock" onClick={() => handleClick(5)} />
                    </ul>
                </div>
            </div>
            <Page1 active={page === 1} deactivating={pageOut === 1} close={closePage} />
            <Page2 active={page === 2} deactivating={pageOut === 2} close={closePage} />
            <Page3 active={page === 3} deactivating={pageOut === 3} close={closePage} />
            <Page4 active={page === 4} deactivating={pageOut === 4} close={closePage} />
            <Page5 active={page === 5} deactivating={pageOut === 5} close={closePage} />
            <Page6 active={page === 6} deactivating={pageOut === 6} close={closePage} />
            <Page7 active={page === 7} deactivating={pageOut === 7} close={closePage} />
            <Page8 active={page === 8} deactivating={pageOut === 8} close={closePage} />
        </AppReloader>
    );
}

const ListItem = ({ name, onClick }) => (
    <li onClick={onClick}>
        <h2>{name}</h2>
        <i />
    </li>
);
