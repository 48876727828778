import React, { useRef } from "react";

export const AppReloader = ({ children }) => {
  const clicks = useRef(0);
  const lastClick = useRef(null);

  const onClick = () => {
    if (lastClick.current && new Date().getTime() - lastClick.current > 500) {
      clicks.current = 0;
      lastClick.current = null;
      return;
    }

    if (clicks.current === 5) {
      document.location.reload();
      return;
    }

    clicks.current += 1;
    lastClick.current = new Date().getTime();
  };
  return (
    <div onClick={onClick}>{children}</div>
  )
}