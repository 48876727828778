import React from "react";
import { Template } from "./Template";
import src from "./assets/little.mp3";

export const Page5 = (props) => {
  return (
    <Template
      {...props}
      name="Sweet Honey in the Rock"
      description="Sweet Honey in the Rock sings &ldquo;Joan Little,&rdquo; written by Bernice Johnson Reagon. Introduced by Del Dobbins of Sophie's Parlor Media Collective."
      date="1977"
      credit="Pacifica Radio Archives"
      duration="1:20"
      src={src}
      phrases={phrases}
    />
  );
}

const phrases = [
  {
    start: 300,
    end: 2801,
    text: 'Now, buried in that kind of thinking, as well,'
  },
  {
    start: 2801,
    end: 7801,
    text: 'is an assumption or a presumption that women who are raped'
  },
  {
    start: 8850,
    end: 11637,
    text: 'are, quote, "Certain kind of women."'
  },
  {
    start: 12720,
    end: 16950,
    text: 'One area composer, artist, and feminist, friend of ours,'
  },
  {
    start: 16950,
    end: 20790,
    text: "friend of Sophie's, friend of WPFW's, Bernice Reagon,"
  },
  { start: 20790, end: 22530, text: 'tackled that assumption,' },
  {
    start: 22530,
    end: 24930,
    text: 'the assumption of being a certain kind of woman'
  },
  { start: 24930, end: 26730, text: 'in her tribute to Joan Little,' },
  {
    start: 26730,
    end: 29467,
    text: 'as Bernice notes in the song coming up,'
  },
  {
    start: 29467,
    end: 33562,
    text: `"Joan is you, she's me, she's all of us."`,
    paragraph: true,
  },
  { start: 33562, end: 37721, text: '♪ Who is this girl ♪ ', break: true },
  { start: 37721, end: 41337, text: '♪ And what is she to you ♪ ', break: true },
  { start: 41337, end: 45185, text: '♪ Joan Little ♪ ', break: true },
  { start: 45185, end: 48437, text: "♪ She's my sister ♪ ", break: true },
  { start: 48437, end: 51352, text: '♪ Joan Little ♪ ', break: true },
  { start: 51352, end: 54186, text: "♪ She's our mama ♪ ", break: true },
  {
    start: 54186,
    end: 59186,
    text: "♪ Joan Little, she's your lover ♪ ", break: true
  },
  {
    start: 59724,
    end: 64724,
    text: "♪ Joan's the woman who's gonna carry your child ♪ ", break: true
  },
  {
    start: 67535,
    end: 72535,
    text: "♪ I've always been told since the day I was born ♪ ", break: true
  },
  {
    start: 72957,
    end: 77302,
    text: '♪ Leave those no good women alone ♪ ', break: true
  },
  {
    start: 77302,
    end: 80795,
    text: '♪ Trying to keep your nose clean ♪', break: true
  }
];
